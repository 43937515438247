<template>
  <transition name="fade">
    <div v-if="isVisible" :class="type=='fail'?'fail message-box':'message-box'">
      <p > <img :src="type=='fail'?failUrl:successUrl" />{{ message }}</p>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      isVisible: true,
      timer: null,
      successUrl: require('@/assets/success-icon.png'),
      failUrl: require('@/assets/fail-icon.png'),
    }
  },
  mounted () {
    // 设置一个定时器，在2秒后隐藏弹窗
    this.timer = setTimeout(() => {
    //   this.isVisible = false
      this.$emit('close')
    }, 2 * 1000)
  },
  beforeDestroy () {
    // 清除定时器，以防组件被销毁时定时器还在运行
    if (this.timer) {
      clearTimeout(this.timer)
    }
  },
}
</script>

<style scoped>
.message-box {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  height:42px;
  padding:0px 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  font-family: HarmonyOS Sans SC;
font-size: 18px;
font-weight: 400;
line-height: 22px;

  p{
    display: flex;
    justify-content: center;
    align-items: center;
            width: 100%;
        height: 100%;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.fail{
  background: #FDEBEB;

}
</style>

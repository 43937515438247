<template>
  <div class="wrapper">
    <img
      class="close-btn"
      @click="closePage"
      src="../../../../assets/class/close.png"
    />
    <div class="container">
      <p class="apply-title">申请成为课题伙伴</p>
      <div class="steps-container">
        <ul class="steps">
          <li v-for="(item, index) in steps" :key="index">
            <div v-if="index == 1 || index == 3" class="divider"></div>
            <div
              v-else
              :class="current === index + 1 ? 'active step' : 'step'"
              @click="switchClass(index)"
            >
              <div class="circle">
                {{ index == 0 ? 1 : index == 2 ? 2 : 3 }}
              </div>
              <div class="name">{{ item }}</div>
            </div>
          </li>
        </ul>
      </div>
      <div class="guide " v-if="current == 1">
        <div class="description">
          <p>
            新能源汽车智能制造国家地方联合工程研究中心（简称：工程研究中心）于2019年由国家发改委批准建设，是吉利汽车研究院（宁波）有限公司的成果转化和产业化平台。工程研究中心围绕新能源汽车智能制造领域开展整体解决方案以及相关的核心工程能力研究与开发，从而实现市场需求驱动、研发和生产一体化的规模化定制新能源汽车智能制造新模式，并最终打造新能源汽车“智能工厂原型”和“互联网协同制造平台”。
          </p>
          <p>
            为加强工程研究中心在新能源汽车智能制造相关基础理论、应用基础研究、共性技术和装备开发，制定本申请指南。国内外学者可依据开放课题管理办法提出开放课题申请，经中心择优立项。
          </p>
        </div>
        <div class="guide-item">
          <ul>
            <li v-for="item in guideItems" :key="item">
              {{ item }}
            </li>
          </ul>
        </div>
        <div class="contact-methods">
          <p>
            <span class="label">
              <span>联</span><span>系</span><span>方</span><span>式:</span
              ><span></span
            ></span>
            <span class="content"
              >新能源汽车智能制造国家地方联合工程研究中心</span
            >
          </p>
          <p>
            <span class="label"><span>电</span><span>话:</span></span>
            <span class="content">15012818408</span>
          </p>
          <p>
            <span class="label"> <span>地</span><span>址:</span></span>
            <span class="content"
              >浙江省宁波市慈溪市杭州湾新区滨海四路吉利汽车研究院一期</span
            >
          </p>
          <p>
            <span class="label"><span>邮</span><span>编:</span></span>
            <span class="content">315336</span>
          </p>
        </div>
      </div>
      <div class="download-link popup" v-if="current == 3">
        <div class="item-container">
          <div class="link" v-for="item in downloadLinks" :key="item.link">
            <img :src="docImgUrl" alt="模版" />
            <p class="name">{{ item.name }}</p>
            <a class="download" :href="item.link" :download="item.name">
              下载</a
            >
          </div>
        </div>
      </div>
      <div class="form" v-if="current == 5">
        <div class="row-item">
          <div class="input-container" style="width: 691px">
            <input
              class="input-field"
              v-model="applyName"
              placeholder="请输入姓名"
            />
            <i class="icon">*</i>
          </div>
          <div class="input-container" style="width: 691px">
            <input
              class="input-field"
              v-model="applyEmail"
              placeholder="请输入邮箱"
            />
            <i class="icon">*</i>
          </div>
        </div>
        <div class="row-item">
          <div class="input-container" style="width: 691px">
            <input
              class="input-field"
              v-model="applyPhone"
              placeholder="请输入手机号"
            />
            <i class="icon">*</i>
          </div>
          <div class="input-items">
            <div class="input-container" style="width: 452px">
              <input
                class="input-field"
                v-model="code"
                placeholder="请输入验证码"
              />
              <i class="icon">*</i>
            </div>
            <button
              :class="`get-code ${isSend ? 'disabled' : ''}`"
              :disabled="isSend"
              style="width: 214px"
              @click="sendMessage"
            >
              {{ isSend ? `${validTime}S后可再次发送` : "发送验证码" }}
            </button>
          </div>
        </div>
        <div class="row-item">
          <div class="input-container" style="width: 100%">
            <input
              class="input-field"
              v-model="institutionName"
              placeholder="请输入所属机构名称"
            />
            <i class="icon">*</i>
          </div>
        </div>

        <div class="row-item">
          <div class="input-container" style="width: 100%">
            <input
              class="input-field"
              v-model="topic"
              placeholder="请输入课题名称"
            />
            <i class="icon">*</i>
          </div>

          <input
            id="fileInput"
            style="display: none"
            type="file"
            ref="fileRef"
            multiple
            @change="fileChange"
            accept=".docx"
          />
        </div>
        <!-- <div class="row-item">
          <div class="textarea-container" style="width: 100%">
            <textarea
              class="textarea-field"
              rows="3"
              v-model="briefIntroduction"
              placeholder="请输入课程简介"
            >
            </textarea>

            <i class="icon">*</i>
          </div>
        </div> -->
        <div class="row-item">
          <div
            class="upload-container drop-zone"
            v-if="!filePath"
            @dragover.prevent
            @drop="handleDrop"
          >
            <img :src="uploadIcon" @click="upload" />
            <div>
              <span class="upload-btn" @click="upload">点击上传</span
              >或将《课题申请书》拖入此处，支持.docx文件
            </div>
          </div>
          <div class="upload-container" v-else>
            <div class="upload-success">
              <div class="filename">{{ file.name }}</div>
              <div class="reupload-btn" @click="upload">重新上传</div>
            </div>
          </div>
        </div>
        <div class="apply-info">
          <p>
            *申请者请于<span class="date">2024年 10 月 31 日</span
            >前将课题申请书电子版上传
          </p>
        </div>

        <div class="row-item">
          <div class="btns">
            <button class="cancel-btn" @click="cancelSubmit">取消</button>
            <button class="submit-btn" @click="confirmSubmit">提交</button>
          </div>
        </div>
      </div>
      <div class="apply-info" v-if="current == 1">
        <p>
          *申请者请于<span class="date">2024年 10月 31日</span
          >前将课题申请书电子版上传
        </p>
      </div>
      <div></div>
    </div>
    <Toast
      v-if="show"
      :message="messageContent"
      :type="messageType"
      @close="show = false"
    ></Toast>
  </div>
</template>

<script>
import Toast from '@/components/Toast'
export default {
  name: 'ApplyForm',
  components: {
    Toast,
  },

  data () {
    return {
      applyName: '',
      applyEmail: '',
      applyPhone: '',
      // briefIntroduction: '',
      code: '',
      filePath: '',
      institutionName: '',
      topic: '',
      show: false,
      messageContent: '',
      messageType: '',
      isSend: false,
      validTime: 60,
      uploadIcon: require('@/assets/upload-icon.png'),
      file: null,
      current: 1,
      steps: ['查看申请指南', '', '课题相关模版下载', '', '提交申请表单'],
      guideItems: [
        '申请指南：总装立体库标准件机器人无序抓取',
        '考核指标：研究报告、理论模型、学术论文等。',
        '资助类型：一般课题，资助年限为1~2年；重点课题，资助年限为3年。',
        '资助数量：资助数量和类型由申报团队实力、预期成果、评审情况等综合决定。',
      ],
      downloadLinks: [
        {
          link: '/apply.docx',
          name: '开放课题申请书.docx',
        },
        {
          link: '/agreement.docx',
          name: '开放课题合同书.docx',
        },
        {
          link: '/report.doc',
          name: '开放课题结题报告.doc',
        },
      ],
      docImgUrl: require('@/assets/class/apply-file.svg'),
    }
  },
  watch: {},
  methods: {
    upload () {
      const uploadDiv = document.getElementById('fileInput')
      uploadDiv.value = ''
      uploadDiv.click()
    },
    fileChange (e) {
      // /iot/partner/upload

      const file = e.target.files[0]

      // 创建一个FormData对象
      const formData = new FormData()
      formData.append('file', file)
      // 使用fetch发送POST请求
      fetch('/iot/partner/upload', {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json()) // 根据服务器响应格式调整
        .then((data) => {
          if (data.success) {
            this.filePath = data.msg
            this.file = file
            this.openToast('上传成功', 'success')
          } else {
            this.openToast('上传失败，请稍后重试', 'fail')
          }
        })
        .catch((error) => console.error(error))
    },
    openToast (text, type) {
      this.show = true
      this.messageContent = text
      this.messageType = type
    },
    sendMessage () {
      const phone = this.applyPhone
      if (!phone) return this.openToast('请输入手机号', 'fail')
      fetch(`/iot/partner/sendSms?phone=${phone}`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            this.isSend = true
            this.openToast('发送成功', 'success')
            setInterval(() => {
              if (this.validTime) {
                this.validTime--
              }
            }, 1000)
            setTimeout(() => {
              this.isSend = false
            }, 60 * 1000)
          }
        })
        .catch(() => this.openToast('发送失败', 'fail'))
    },

    confirmSubmit () {
      if (!this.applyName) return this.openToast('请输入姓名', 'fail')
      if (!this.applyEmail) return this.openToast('请输入邮箱', 'fail')
      if (!this.applyPhone) return this.openToast('请输入手机号', 'fail')
      if (!this.code) return this.openToast('请输入验证码', 'fail')
      if (!this.institutionName) {
        return this.openToast('请输入机构名称', 'fail')
      }
      if (!this.topic) return this.openToast('请输入课题名称', 'fail')
      // if (!this.briefIntroduction) {
      //   return this.openToast('请输入课程简介', 'fail')
      // }
      if (!this.filePath) return this.openToast('请上传课题申请书', 'fail')

      // /iot/partner/addPo
      fetch('/iot/partner/addPo', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          applyName: this.applyName,
          applyEmail: this.applyEmail,
          applyPhone: this.applyPhone,
          // briefIntroduction: this.briefIntroduction,
          code: this.code,
          filePath: this.filePath,
          institutionName: this.institutionName,
          topic: this.topic,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            this.openToast(data.msg, 'success')
            this.$router.push('/classes#process')
          } else {
            this.openToast(data.msg, 'fail')
          }
        })
        .catch(() => this.openToast('提交失败，请稍后重试', 'fail'))
    },
    cancelSubmit () {
      this.$router.push('/classes#process')
    },
    closePage () {
      this.$router.push('/classes#process')
    },

    handleDrop (event) {
      this.isDragging = false
      const files = event.dataTransfer.files
      // 处理上传逻辑
      this.uploadFiles(files)
    },
    uploadFiles (files) {
      // 这里可以添加处理文件上传到服务器的逻辑
      console.log('上传文件:', files[0])
      if (!files[0].name.includes('.docx')) {
        return this.openToast('上传失败，请核对文件格式', 'fail')
      }
      // 创建一个FormData对象
      const formData = new FormData()
      formData.append('file', files[0])
      // 使用fetch发送POST请求
      fetch('/iot/partner/upload', {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json()) // 根据服务器响应格式调整
        .then((data) => {
          if (data.success) {
            this.filePath = data.msg
            this.file = files[0]
            this.openToast('上传成功', 'success')
          } else {
            this.openToast('上传失败，请稍后重试', 'fail')
          }
        })
        .catch(() => this.openToast('上传失败，请稍后重试', 'fail'))
    },
    switchClass (index) {
      this.current = index + 1
    },
  },
}
</script>
<style lang="less" scoped>
@import "~@/style/global";

.wrapper {
  .g_center_wrapper();
  background: #f8f8f8;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(30px);
  background: #00000099;
  box-shadow: 0px 4px 4px 0px #00000040;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  overflow: scroll;
}
.container {
  position: absolute;
  top: 100px;
  left: 50%;
  width: 1420px;
  min-height: 650px;
  transform: translateX(-50%);
}
.row-item {
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // input{
  //     height:55px;
  //     background:#fff;
  //     font-size:20px;
  //     line-height:28px;
  //     text-indent:20px;
  //     border:none;
  //     margin:10px 10px;
  // }
  .input-container {
    position: relative;
    margin: 10px 0;
    input {
      text-indent: 10px;
    }
    input:focus {
      border: none;
      outline: none;
    }

    input::placeholder {
      font-family: PingFang SC;
      font-size: 20px;
      font-weight: 400;
      color: #a1a1a1;
    }
  }
  .input-field {
    padding-left: 30px; /* 确保输入内容不会被图标覆盖 */
    width: 100%;
    height: 55px;
    background: #fff;
    font-size: 20px;
    line-height: 28px;
    box-sizing: border-box;
    border: none;
  }
  .textarea-field {
    width: 100%;
    padding-left: 30px; /* 确保输入内容不会被图标覆盖 */
    line-height: 28px;
    border: none;
    font-size: 20px;
    box-sizing: border-box;
    background: #fff;
    font-family: initial;
    padding-top: 15px;
    text-indent: 10px;
  }
  .textarea-field:focus {
    border: none;
    outline: none;
  }
  .textarea-field::placeholder {
    font-family: PingFang SC;
    font-size: 20px;
    font-weight: 400;
    color: #a1a1a1;
  }
  .textarea-container {
    position: relative;
    margin: 10px 0;
  }
  .textarea-container .icon {
    position: absolute;
    left: 20px; /* 图标距离输入框左侧的距离 */
    top: 22%; /* 使图标垂直居中 */
    transform: translateY(-50%); /* 对图标进行垂直居中对齐 */
    font-size: 20px; /* 图标大小 */
    color: red;
  }
  .input-container .icon {
    position: absolute;
    left: 20px; /* 图标距离输入框左侧的距离 */
    top: 50%; /* 使图标垂直居中 */
    transform: translateY(-50%); /* 对图标进行垂直居中对齐 */
    font-size: 20px; /* 图标大小 */
    color: red;
  }
}
.apply-title {
  font-size: 32px;
  font-weight: 600;
  line-height: 44.8px;
  text-align: left;
  color: #fff;
  margin-bottom: 20px;
}
.apply-info {
  display: flex;
  align-items: center;
  gap: 20px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  margin: 20px 0;
  font-weight: 300;

  .date {
    color: #fff;
  }
  a {
    color: #3ebaff;
    font-size: 22px;
  }
}
.get-code {
  height: 55px;
  background: #000;
  color: #fff;
  line-height: 55px;
  font-size: 20px;
  cursor: pointer;
}
.disabled {
  background: gray;
}
.upload-file {
  height: 55px;
  background: #e0ebff;
  color: #0057ff;
  line-height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border: none;
}
.btns {
  margin: 20px auto;
  button {
    width: 295px;
    height: 64px;
    font-size: 20px;
    text-align: center;
    color: #fff;
    border: none;
  }
  .cancel-btn {
    background: #000;
    margin-right: 20px;
    cursor: pointer;
  }
  .submit-btn {
    background: #0057ff;
    cursor: pointer;
  }
}
.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.input-items {
  width: 691px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.upload-container {
  width: 100%;
  background: #e0ebff;
  border: 1px dashed #4d6aec;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: PingFang SC;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  padding: 15px;
  margin-top: 5px;
  color: #666666;
  img {
    cursor: pointer;
  }
}
.upload-btn {
  color: #4d6aec;
  font-weight: 500;
  cursor: pointer;
  font-family: PingFang SC;
  display: inline-block;
  padding-right: 5px;
}
.upload-success {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.filename {
  font-family: PingFang SC;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  color: #0057ff;
}
.reupload-btn {
  width: 128px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-family: PingFang SC;
  font-size: 20px;
  font-weight: 400;
  border: 1px solid #0057ff;
  background-color: #fff;
  margin-left: 20px;
  cursor: pointer;
}
.drop-zone:hover,
.drop-zone.dragover {
  border-color: #666;
}
.steps {
  display: flex;
  justify-content: space-between;
  height: 144px;
  align-items: center;
  .circle {
    width: 64px;
    height: 64px;
    color: #000;
    border-radius: 50%;
    line-height: 64px;
    background: #ffffffe5;
    text-align: center;
    font-family: HarmonyOS Sans SC;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .divider {
    width: 216px;
    height: 2px;
    background-color: #fff;
  }
  .name {
    font-family: HarmonyOS Sans SC;
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
  }
  .active {
    .circle {
      color: #fff;
      background: #1821f2;
    }
    .name {
      color: rgba(255, 255, 255, 0.9);
    }
  }

  .step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.guide {
  .description {
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 300;
    line-height: 25.6px;
    color: #2b2b2b;
  }
  .guide-item {
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 500;
    line-height: 25.6px;
    margin: 30px 0;
    li {
      list-style: disc;
      margin-left: 25px;
    }
  }
  .contact-methods {
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 300;
    line-height: 25.6px;
    p {
      display: flex;
    }

    .label {
      width: 80px;
      display: flex;
      justify-content: space-between;
    }
    .content {
      margin-left: 10px;
    }
  }
}
.popup ,.guide{
  position: relative;
  border: 1px solid #ccc;
  width: 1413px;
  height: 474px;
  background-color: #fff;
  padding: 30px;
  box-sizing: border-box;
  margin-top: 30px;
}
.popup::before {
  content: "";
  position: absolute;
  top: -20px; /* 调整气泡位置 */
  left: 50%; /* 水平居中 */
  transform: translateX(-50%);
  border-width: 0 30px 20px 30px;
  border-style: solid;
  border-color: transparent transparent #ccc transparent;
  border-radius: -5px;
}

.popup::after {
  content: "";
  position: absolute;
  top: -20px; /* 调整气泡位置 */
  left: 50%; /* 水平居中 */
  transform: translateX(-50%);
  border-width: 0 30px 20px 30px;
  border-style: solid;
  border-color: transparent transparent white transparent;
  border-radius: -5px;
}
.form {
  background: none;
  margin-top: 20px;
}
.download-link {
  display: flex;
  align-items: center;
  justify-content: center;
  .item-container {
    width: 830px;
    height: 222px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-width: 190px;
    height: 222px;
    .name {
      font-family: PingFang SC;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.8);
    }
    .download {
      display: inline-block;
      width: 88px;
      height: 48px;
      line-height: 48px;
      border: 1px solid #0057ff;
      text-align: center;
      font-family: PingFang SC;
      font-size: 20px;
      font-weight: 400;
      color: #0057ff;
      text-decoration: none;
    }
  }
}
.guide::before {
  content: "";
  position: absolute;
  top: -20px; /* 调整气泡位置 */
   left: 60px;
  transform: translateX(-50%);
  border-width: 0 30px 20px 30px;
  border-style: solid;
  border-color: transparent transparent #ccc transparent;
  border-radius: -5px;
}

.guide::after {
  content: "";
  position: absolute;
  top: -20px; /* 调整气泡位置 */
  left: 60px;
  transform: translateX(-50%);
  border-width: 0 30px 20px 30px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}
</style>
